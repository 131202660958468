import React, { useState, useEffect } from 'react';

import { Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as usersActions from '../../store/users/actions';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import {
  getFilter,
  getLabel,
  getSort,
  userName,
} from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const NextAppointments = ({ nextAppointments }) => {
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const users = useSelector((state) => state.User.users);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
    dispatch(usersActions.syncUsers());
  }, []);

  const columns = [
    {
      title: 'Acquisition Order',
      name: 'acquisitionOrderId',
      width: 160,
      formatterComponent: ({ value }) => getLabel(value, acquisitionOrders),
      sorting: (a, b) =>
        getSort(getLabel(a, acquisitionOrders), getLabel(b, acquisitionOrders)),
      filter: (value, filter) =>
        getFilter(getLabel(value, acquisitionOrders), filter.value),
    },
    {
      title: 'Phone',
      name: 'phone',
      width: 140,
    },
    {
      title: 'Name',
      name: 'leadName',
    },
    {
      title: 'Appointment date',
      name: 'appointmentDate',
      width: 180,
      formatterComponent: ({ value }) =>
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: (value, filter) =>
        getFilter(
          value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
          filter.value,
        ),
    },
    {
      title: 'Appointment with',
      name: 'appointmentUserId',
      formatterComponent: ({ value }) => userName(value, users),
      sorting: (a, b) => getSort(userName(a, users), userName(b, users)),
      filter: (value, filter) =>
        getFilter(userName(value, users), filter.value),
    },
  ];

  return (
    <Card>
      <CardBody>
        <DataTableNext
          loading={false}
          rows={nextAppointments}
          columns={columns}
          filters={filters}
          onFiltersChange={setFilters}
          sorting={sorting}
          onSortingChange={setSorting}
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </CardBody>
    </Card>
  );
};

export default NextAppointments;
