import React from 'react';
import { EditButton } from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabels,
  getSort,
} from '../Common/DataTable/tableHelpers';

const createTableColumns = (openEditModal, leadFields) => [
  {
    title: 'Name',
    name: 'name',
    width: 250,
  },
  {
    title: 'Fields',
    name: 'leadFields',
    formatterComponent: ({ value }) => getLabels(value, leadFields),
    sorting: (a, b) =>
      getSort(getLabels(a, leadFields), getLabels(b, leadFields)),
    filter: (value, filter) =>
      getFilter(getLabels(value, leadFields), filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
