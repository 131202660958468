import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import * as leadsActions from '../../store/leads/actions';
import * as leadFormsActions from '../../store/leadForms/actions';
import { isAdmin as isAdminSelector } from '../../selectors/auth';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import Modal from '../../components/Common/Modal/Modal';
import ImportLeads from '../../components/Leads/ImportLeads';
import FormLead from '../../components/Leads/FormLead';

import { useTranslation } from 'react-i18next';

const Create = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);
  const loadingLeadForms = useSelector((state) => state.LeadForm.loading);
  const loadingMonetizationOrders = useSelector(
    (state) => state.MonetizationOrder.loading,
  );
  const loading =
    loadingCampaigns || loadingLeadForms || loadingMonetizationOrders;

  const [importModal, setImportModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFormsActions.syncLeadForms());
  }, []);

  const handleOnFileCSVLoad = (
    importLeadType,
    campaignId,
    startDate,
    endDate,
    costPerLead,
    data,
  ) => {
    dispatch(
      leadsActions.importLeads(
        importLeadType,
        campaignId,
        new Date(`${startDate} 00:00:00`),
        new Date(`${endDate} 23:59:59`),
        costPerLead,
        data,
      ),
    );
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t('Create Lead')}
            onCsvImport={() => setImportModal(true)}
            loading={loading}
          />
          {isAdmin && <FormLead create loading={loading} />}
          <Modal
            size="xl"
            title={t('Import Leads from CSV')}
            isOpen={importModal}
            toggle={() => setImportModal(!importModal)}
            showClose
            scrollable={false}>
            <ImportLeads onFileLoad={handleOnFileCSVLoad} />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Create;
