import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const CampaignSourceLink = ({ campaign }) => {
  let icon = '';
  switch (campaign.source) {
    case 'googleAds':
      icon = 'bxl-google';
      break;
    default:
      icon = 'bxl-facebook-square';
      break;
  }
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'campaign' + campaign.id}
        onClick={() => window.open(campaign.link, '_blank')}>
        <i className={`bx ${icon}`}></i>
        <UncontrolledTooltip placement="top" target={'campaign' + campaign.id}>
          Go to campaign
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

const createTableColumns = (
  openViewModal,
  openEditModal,
  acquisitionOrders,
) => [
  {
    title: 'Name',
    name: 'name',
    sort: true,
  },
  {
    title: 'Source',
    name: 'source',
    width: 120,
    sort: true,
  },
  {
    title: 'Acquisition Order',
    name: 'acquisitionOrderId',
    sort: true,
    formatterComponent: ({ value }) => getLabel(value, acquisitionOrders),
    sorting: (a, b) =>
      getSort(getLabel(a, acquisitionOrders), getLabel(b, acquisitionOrders)),
    filter: (value, filter) =>
      getFilter(getLabel(value, acquisitionOrders), filter.value),
  },
  {
    title: 'Archived',
    name: 'archived',
    width: 120,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-warning font-size-12">YES</span>
      ) : (
        <span className="badge badge-success font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(`${moment(value).fromNow()}`, filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 150,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        {(row.source === 'googleAds' || row.source === 'facebook') && (
          <CampaignSourceLink campaign={row} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
