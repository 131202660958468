import React from 'react';
import {
  ViewButton,
  EditButton,
  ViewLeadButton,
  ExtraCallButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
  userName,
} from '../Common/DataTable/tableHelpers';

import callOutcomes from '../../config/callOutcomes';
import moment from 'moment';
import { capitalize } from '../../helpers/sharedFunction';

const createTableColumns = (
  openLeadModal,
  openViewModal,
  openEditModal,
  openExtraCallModal,
  isTeamLeaderAndUp,
  acquisitionOrders,
  users,
  t,
  hideAdminColumns = false,
  hideHistoryColumns = false,
) => [
  {
    title: 'Acquisition Order',
    name: 'acquisitionOrderId',
    width: 160,
    formatterComponent: ({ value }) => getLabel(value, acquisitionOrders),
    sorting: (a, b) =>
      getSort(getLabel(a, acquisitionOrders), getLabel(b, acquisitionOrders)),
    filter: (value, filter) =>
      getFilter(getLabel(value, acquisitionOrders), filter.value),
    hidden: hideAdminColumns,
  },
  {
    title: 'Phone',
    name: 'phone',
    width: 140,
  },
  {
    title: 'Lead ID',
    name: 'leadId',
    hidden: true,
  },
  {
    title: 'Name',
    name: 'leadName',
    hidden: hideAdminColumns || hideHistoryColumns,
  },
  {
    title: 'Operator',
    name: 'userId',
    formatterComponent: ({ value }) => userName(value, users),
    sorting: (a, b) => getSort(userName(a, users), userName(b, users)),
    filter: (value, filter) => getFilter(userName(value, users), filter.value),
    hidden: hideAdminColumns,
  },
  {
    title: 'Status',
    name: 'status',
    width: 100,
    formatterComponent: ({ value }) =>
      value === 'closed' ? (
        <span className="badge badge-success font-size-12">{t(value)}</span>
      ) : (
        <span className="badge badge-secondary font-size-12">{t(value)}</span>
      ),
    sorting: (a, b) => getSort(t(a), t(b)),
    filter: (value, filter) => getFilter(t(value), filter.value),
  },
  {
    title: 'Inbound',
    name: 'inbound',
    width: 110,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),

    hidden: !hideAdminColumns,
  },
  {
    title: 'Type',
    name: 'type',
    width: 120,
    formatterComponent: ({ value }) =>
      value === 'new' ? (
        <span className="badge badge-secondary font-size-12">{t(value)}</span>
      ) : (
        <span className="badge badge-warning font-size-12">{t(value)}</span>
      ),
    sorting: (a, b) => getSort(t(a), t(b)),
    filter: (value, filter) => getFilter(t(value), filter.value),
  },
  {
    title: 'Appointment date',
    name: 'appointmentDate',
    width: 140,
    formatterComponent: ({ value }) =>
      value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
        filter.value,
      ),
    hidden: true,
  },
  {
    title: 'Appointment with',
    name: 'appointmentUserId',
    formatterComponent: ({ value }) => userName(value, users),
    sorting: (a, b) => getSort(userName(a, users), userName(b, users)),
    filter: (value, filter) => getFilter(userName(value, users), filter.value),
    hidden: true,
  },
  {
    title: 'Outcome',
    name: 'outcome',
    formatterComponent: ({ value }) => {
      if (!value) return '';
      const { color, title } = callOutcomes[value];
      return (
        <span className={`badge badge-${color} font-size-12 text-wrap`}>
          {t(title)}
        </span>
      );
    },
    sorting: (a, b) =>
      getSort(
        a ? t(callOutcomes[a].title) : '',
        b ? t(callOutcomes[b].title) : '',
      ),
    filter: (value, filter) =>
      getFilter(value ? t(callOutcomes[value].title) : '', filter.value),
  },
  {
    title: 'Reason',
    name: 'negativeOutcome',
    formatterComponent: ({ value }) => (value ? t(capitalize(value)) : ''),
    sorting: (a, b) => getSort(a || '', b || ''),
    filter: (value, filter) => getFilter(value || '', filter.value),
  },
  {
    title: 'Attempt',
    name: 'count',
    width: hideAdminColumns ? 120 : 90,
  },
  {
    title: 'Called At',
    name: 'startDateCall',
    width: hideAdminColumns ? 200 : 140,
    formatterComponent: ({ value }) =>
      value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
        filter.value,
      ),
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => t('date_fromNow', { date: value }),
    filter: (value, filter) =>
      getFilter(t('date_fromNow', { date: value }), filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 150,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {row.status === 'new' && (
          <EditButton row={row} onPress={openEditModal} />
        )}
        <ViewLeadButton row={row} onPress={openLeadModal} />
        {row.nextCall === 'none' && isTeamLeaderAndUp && (
          <ExtraCallButton row={row} onPress={openExtraCallModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    hidden: hideAdminColumns || hideHistoryColumns,
  },
];

export default createTableColumns;
