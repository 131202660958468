import React from 'react';
import {
  getFilter,
  getLabels,
  getSort,
  userName,
} from '../Common/DataTable/tableHelpers';
import { capitalize } from '../../helpers/sharedFunction';

const createTableColumns = (
  acquisitionOrders,
  monetizationOrders,
  users,
  t,
) => [
  {
    title: 'Acquisition Order',
    name: 'acquisitionOrderId',
    formatterComponent: ({ value }) => getLabels(value, acquisitionOrders),
    sorting: (a, b) =>
      getSort(getLabels(a, acquisitionOrders), getLabels(b, acquisitionOrders)),
    filter: (value, filter) =>
      getFilter(getLabels(value, acquisitionOrders), filter.value),
  },
  {
    title: 'Closure',
    width: 160,
    name: 'closure',
    formatterComponent: ({ row }) =>
      row.revenue > 0 && row.status === 'closed' ? (
        <span className="badge badge-success font-size-12">
          {t('Positive Closure')}
        </span>
      ) : (
        <span className="badge badge-danger font-size-12">
          {t('Negative Closure')}
        </span>
      ),
    filter: (value, filter, row) =>
      getFilter(
        `${
          row.revenue > 0 && row.status === 'closed' ? 'positive' : 'negative'
        } closure`,
        filter.value,
      ),
    sorting: 'disabled',
  },
  {
    title: 'Closure At',
    name: 'createdAt',
    width: 250,
    formatterComponent: ({ value }) => t('date_fromNow', { date: value }),
    filter: (value, filter) =>
      getFilter(t('date_fromNow', { date: value }), filter.value),
  },
  {
    title: 'Monetization Order',
    name: 'monetizationOrderId',
    formatterComponent: ({ value }) => getLabels(value, monetizationOrders),
    sorting: (a, b) =>
      getSort(
        getLabels(a, monetizationOrders),
        getLabels(b, monetizationOrders),
      ),
    filter: (value, filter) =>
      getFilter(getLabels(value, monetizationOrders), filter.value),
  },
  {
    title: 'Outcome',
    formatterComponent: ({ row }) =>
      row.positiveOutcome || row.negativeOutcome
        ? t(capitalize(row.positiveOutcome || row.negativeOutcome))
        : '',
    filter: (value, filter, row) =>
      getFilter(
        row.positiveOutcome || row.negativeOutcome
          ? t(capitalize(row.positiveOutcome || row.negativeOutcome))
          : '',
        filter.value,
      ),
    sorting: 'disabled',
  },
  {
    title: 'Operator',
    width: 150,
    name: 'userId',
    formatterComponent: ({ value }) => userName(value, users),
    sorting: (a, b) => getSort(userName(a, users), userName(b, users)),
    filter: (value, filter) => getFilter(userName(value, users), filter.value),
  },
];

export default createTableColumns;
